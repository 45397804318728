.try {
  margin: 50px 0;
}

.try a {
  display: block;
  font-size: 1.25rem;
  background-color: var(--main);
  color: #fff;
  padding: 15px 30px;
  font-family: inherit;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  text-decoration: none;
}

.try a:hover {
  cursor: pointer;
  background-color: var(--hover);
}

.try p {
  font-size: 0.9rem;
  color: #78ff78;
}
